import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { AudioBasicWithPick } from '../AudioBasicWithPick/AudioBasicWithPick';
import classNames from './AudiosBasicWithPick.module.scss';
import { listColumnsSize } from './constants';

export function AudiosBasicWithPick({
  audios,
  selectedId,
  onClick,
}) {
  return (
    <>
      <Row className={classNames.listTile}>
        <Col sm={listColumnsSize.title} className={classNames.listTileColumn}>
          Title / Path
        </Col>
        <Col sm={listColumnsSize.duration} className={classNames.listTileColumn}>
          Duration
        </Col>
        <Col sm={listColumnsSize.typeShort} className={classNames.listTileColumn}>
          Type
        </Col>
      </Row>
      <ul className={classNames.list}>
        {
        audios.map((audio) => (
          <li
            key={audio.id}
            className={classNames.listItem}
          >
            <AudioBasicWithPick
              key={audio.id}
              audio={audio}
              isSelected={audio.id === selectedId}
              onClick={onClick}
            />
          </li>
        ))
      }
      </ul>
    </>
  );
}
