import cs from 'classnames';
import {useField, useFormikContext} from 'formik';
import React from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import {useAchievementsContext} from '../../../../contexts/AchievementsContext/AchievementsContext';
import {useMemoryBankContext} from '../../../../contexts/MemoryBankContext/MemoryBankContext';
import {useMemoryIconsContext} from '../../../../contexts/MemoryIconsContext/MemoryIconsContext';
import {IAchievementUsedInStory} from '../../../../dorian-shared/types/achievement/Achievement';
import {BadgeWithTooltip} from '../../../ui/AvatarTool/BadgeWithTooltip/BadgeWithTooltip';
import {MemoryType, MemoryValue} from '../../Book/MemoryBank/memoryBankTypes';
import {MemoryIconField} from '../../Book/MemoryBank/MemoryFields/MemoryIconField';
import {IAchievementModal} from '../AchievementsModalTypes';
import {useAchievementsModalContext} from '../context/AchievementsModalContext';
import {AchievementField} from './AchievementField';
import css from './AchievementRow.module.scss';

export type AchievementRowProps = {
  index: number,
  confirmDelete: (
    achievementId: number,
    onConfirm: () => void, onCancel: () => void) => void,
}

const getAchievementUsedTooltipContent = (usedInStory: IAchievementUsedInStory[], isOwnedByUser: boolean) => {
  if (usedInStory.length > 0) {
    const firstItem = usedInStory[0];
    return (
      <>
        <b>Used in:</b>
        {` ${firstItem.Book}`}
        <br/>
        <b>Episode:</b>
        {` ${firstItem.Episode}`}
        <br/>
        <b>Revision:</b>
        {` ${firstItem.Revision}`}
        <br/>
        <b>Node:</b>
        {` ${firstItem.Node}`}
      </>
    );
  }

  if (isOwnedByUser) {
    return (
      <>
        <b>Already granted to users.</b>
        <br/>
        Once achieved by a user, an achievement cannot be deleted
      </>
    );
  }

  return '';
};

export function AchievementRow(props: AchievementRowProps) {
  const {index, confirmDelete} = props;

  const {memories} = useMemoryBankContext();
  const {isSendNotifications} = useAchievementsContext();

  const { memoryIcons, createMemoryIcon } = useMemoryIconsContext();
  const { hasValueChanged, achievements, episodes } = useAchievementsModalContext();
  const {
    values, setValues, handleChange, errors, setFieldError, setFieldValue,
  } = useFormikContext<IAchievementModal[]>();

  const value = values[index];
  const [icon] = useField(`[${index}].icon`);
  const [achievementId] = useField(`[${index}].id`);
  const [isManual, , isManualHelpers] = useField(`[${index}].isManual`);

  // TODO: Change chapter to isBonus in the future
  const canBeMissedEpisodes = episodes.filter((episode) => Boolean(episode.chapter));
  const currentCanBeMissedEpisode = canBeMissedEpisodes.find((episode) => episode.uuid === value.unavailableAfter);
  const unavailableAfterTitle = currentCanBeMissedEpisode
    ? `${currentCanBeMissedEpisode.chapter}. ${currentCanBeMissedEpisode.title}`
    : '';

  const numericMemories = memories.filter((memory) => memory.type === MemoryType.Number);
  const availableMemories = numericMemories.filter((memory) => {
    const usedAchievement = achievements.find((achievement) => achievement.id === value.id);
    const usedMemoryId = usedAchievement?.check.variableId ?? 0;
    const isUsed = Number(memory.id) === Number(usedMemoryId);
    const isVisible = memory.showIn.length > 0;
    return isUsed || isVisible;
  });

  const checkValue = Number.isNaN(Number(value.check.value)) ? '' : value.check.value as MemoryValue;
  const bonusEpisodes = episodes.filter((episode) => episode.isBonus);

  // We should have custom logic for check value change because we need to prevent non-numeric values in safari
  const handleCheckValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(Number(e.target.value))) {
      return;
    }
    handleChange(e);
  };

  // We should have custom logic for name change
  // because we can't catch that value in Yup validation schema
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isNameExist = values.some((v, i) => v.name === e.target.value && i !== index);
    if (isNameExist) {
      // Set the value without validation, since the message error will be reset after Yup validation
      setFieldValue(`[${index}].name`, e.target.value, false);
      setFieldError(`[${index}].name`, 'Name should be unique');
      return;
    }
    handleChange(e);
  };

  const handleDeleteButtonClick = async () => {
    confirmDelete(
      achievementId.value,
      () => {
        setValues((prevValues) => prevValues.filter((v) => v.id !== achievementId.value));
      },
      () => undefined,
    );
  };

  const usedInStoryLength = value.usedInStory?.length ?? 0;
  const isDeleteDisabled = usedInStoryLength > 0 || value.isOwnedByUser;

  return (
    <div className={cs(css.row, hasValueChanged(value) && css.changed)}>
      <Row
        id={`achievement-row-1-${index}`}
      >
        <Col>
          <Row>
            <Col lg={2}>
              <AchievementField
                id={`[${index}].name`}
                label="Achievement Name"
                placeholder="Achievement Name"
                onChange={handleNameChange}
              />
            </Col>
            <Col lg={2}>
              <AchievementField
                as="select"
                id={`[${index}].check.variableId`}
                label="Memory"
              >
                <option value="0" disabled>[Select]</option>
                {availableMemories.map((memory) => (
                  <option key={memory.id} value={memory.id}>
                    {memory.name}
                  </option>
                ))}
              </AchievementField>
            </Col>
            <Col sm="auto" className="mt-4">
              At least
            </Col>
            <Col lg={2} md={3}>
              <AchievementField
                id={`[${index}].check.value`}
                label="Value"
                placeholder="Value"
                className="position-relative m-0"
                value={checkValue}
                onChange={handleCheckValueChange}
              />
              <div className={css.checkWrapper}>
                  <Form.Check
                    type="switch"
                    id={`[${index}].isManual`}
                    name={`[${index}].isManual`}
                    label="Manual only"
                    checked={isManual.value}
                    onChange={(e) => isManualHelpers.setValue(e.target.checked)}
                    style={{transform: 'scale(0.75) translateX(-15%)', width: '135%'}}
                    />
              </div>
              {isManual.value && (
                <AchievementField
                  as="select"
                  id={`[${index}].unavailableAfter`}
                  label="Unavailable After"
                  value={value.unavailableAfter ?? ''}
                  title={unavailableAfterTitle}
                >
                  <option defaultValue="" value="">N/A</option>
                  {canBeMissedEpisodes.map((episode) => {
                    const optionText = episode.title.length > 50
                      ? `${episode.chapter}. ${episode.title.substring(0, 50)}...`
                      : `${episode.chapter}. ${episode.title}`;
                    return (
                      <option key={episode.uuid} value={episode.uuid}>
                        {optionText}
                      </option>
                    );
                  })}
                </AchievementField>
              )}
            </Col>
            <Col md={1}>
              <AchievementField
                as="select"
                id={`[${index}].reward.contentId`}
                label="Reward content"
                value={value.reward?.contentId ?? ''}
                title={bonusEpisodes.find((episode) => episode.uuid === value.reward?.contentId)?.title ?? ''}
                onChange={(e) => {
                  if (e.target.value === '') {
                    setFieldValue(`[${index}].reward.type`, undefined);
                  } else {
                    setFieldValue(`[${index}].reward.type`, 1);
                  }
                  handleChange(e);
                }}
              >
                <option value="">None</option>
                {bonusEpisodes.map((episode) => (
                  <option
                    key={episode.id}
                    value={episode.uuid}
                    title={episode.title}
                  >
                    {
                      episode.title.length > 32
                        ? `${episode.title.slice(0, 32)}...`
                        : episode.title
                    }
                  </option>
                ))}
              </AchievementField>
            </Col>
            <Col>
              <AchievementField
                id={`[${index}].displayName`}
                label="Display Name"
                placeholder="Display Name"
              />
            </Col>
            <Col md={1}>
              <MemoryIconField
                id={`[${index}].icon`}
                memoryIcons={memoryIcons}
                onSave={createMemoryIcon}
                value={icon.value}
                onChange={handleChange}
                isInvalid={!!errors[index]?.icon}
                errorMessage={errors[index]?.icon}
                variant="outline-primary"
              />
            </Col>
            <Col>
              <AchievementField
                id={`[${index}].description`}
                label="Description"
                placeholder="Description"
              />
            </Col>
          </Row>
          {isSendNotifications && (
            <Row
              id={`achievement-row-2-${index}`}
            >
              <Col lg={2}>
                <AchievementField
                  id={`[${index}].notification.title`}
                  label="Notification Title"
                  placeholder="Default Title"
                />
              </Col>
              <Col>
                <AchievementField
                  id={`[${index}].notification.message`}
                  label="Notification Message"
                  placeholder="Default Message"
                />
              </Col>
            </Row>
          )}
        </Col>
        <Col sm="auto" className="my-auto">
          {isDeleteDisabled && (
            <div className="position-absolute" style={{top: '-1rem', right: '0'}}>
              <BadgeWithTooltip
                id={`delete-button_${index}`}
                title="Can't be deleted"
                content={getAchievementUsedTooltipContent(value.usedInStory, value.isOwnedByUser)}
              />
            </div>
          )}
          <Button
            onClick={handleDeleteButtonClick}
            variant="secondary"
            disabled={isDeleteDisabled}
          >
            X
          </Button>
        </Col>
      </Row>
    </div>
  );
}
