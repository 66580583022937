import { isEmpty, sortBy } from 'lodash-es';
import { Branch } from '../../../../dorian-shared/types/branch/Branch';
import { StepTypes } from '../../../../dorian-shared/types/branch/BranchStep';
import { logger } from '../../../../services/loggerService/loggerService';
import { COMMAND_KEY_PATTERN } from '../types/episodeTextImportCommandKeyValues';
import {
  EpisodeTextImportCommandKey,
  EpisodeTextImportCommands,
  TEpisodeTextImportCommands,
  TEpisodeTextImportCommandValue,
} from '../types/episodeTextImportCommandTypes';
import { EpisodeTextColumnKey, EpisodeTextEntityType } from '../types/episodeTextImportTypes';
import { EpisodeTextImport } from './EpisodeTextImport';
import { EpisodeTextImportNode } from './EpisodeTextImportNode';
import { EpisodeTextImportStep } from './EpisodeTextImportStep';

export class EpisodeTextImportHelper {
  public static getRowType(importRow: string[], prevType?: EpisodeTextEntityType): EpisodeTextEntityType {
    const nodeOrSpeaker = importRow[EpisodeTextColumnKey.NodeOrSpeaker] ?? '';
    const nodeOrSpeakerCleared = EpisodeTextImportHelper.removeAllCommandsFromString(nodeOrSpeaker);
    if (nodeOrSpeaker.startsWith('//')) {
      return EpisodeTextEntityType.Skip;
    }
    const text = importRow[EpisodeTextColumnKey.Text] ?? '';
    const textCleared = EpisodeTextImportHelper.removeAllCommandsFromString(text);
    const description = importRow[EpisodeTextColumnKey.Description] ?? '';

    let commandsIn: TEpisodeTextImportCommands = new Map();

    commandsIn = EpisodeTextImportHelper.parseCommands(EpisodeTextColumnKey.NodeOrSpeaker, nodeOrSpeaker, commandsIn);
    commandsIn = EpisodeTextImportHelper.parseCommands(EpisodeTextColumnKey.Text, text, commandsIn);
    commandsIn = EpisodeTextImportHelper.parseCommands(EpisodeTextColumnKey.Description, description, commandsIn);

    const commands = EpisodeTextImportHelper.convertCommandsToRecordType(commandsIn);

    const isChoiceCommand = commands.choice.has(EpisodeTextColumnKey.Text) || commands.choice.has(EpisodeTextColumnKey.Description);
    if (isChoiceCommand) {
      return EpisodeTextEntityType.Choice;
    }

    const isChat = commands.chat.get(EpisodeTextColumnKey.Description);
    if (isChat && !isEmpty(nodeOrSpeakerCleared) && !isEmpty(textCleared)) {
      return EpisodeTextEntityType.Chat;
    }

    const isThink = commands.think.get(EpisodeTextColumnKey.Description);
    if (isThink && !isEmpty(nodeOrSpeakerCleared) && !isEmpty(textCleared)) {
      return EpisodeTextEntityType.Thinking;
    }

    const isReact = commands.react.get(EpisodeTextColumnKey.Description);
    if (isReact && !isEmpty(nodeOrSpeakerCleared)) {
      return EpisodeTextEntityType.Reaction;
    }

    const isGotoFromNode = commands.goto.get(EpisodeTextColumnKey.Text);
    if (isGotoFromNode && isEmpty(nodeOrSpeakerCleared)) {
      return EpisodeTextEntityType.GotoFromNode;
    }

    const isNode = !isEmpty(nodeOrSpeakerCleared) && isEmpty(textCleared);
    if (isNode) {
      return EpisodeTextEntityType.Node;
    }

    if (prevType === EpisodeTextEntityType.Choice || prevType === EpisodeTextEntityType.Answer) {
      return EpisodeTextEntityType.Answer;
    }

    const hasRemember = commands.remember.has(EpisodeTextColumnKey.Text)
      || commands.remember.has(EpisodeTextColumnKey.Description);
    const isRemember = hasRemember && isEmpty(nodeOrSpeakerCleared);
    if (isRemember) {
      return EpisodeTextEntityType.Remember;
    }

    const isCheck = commands.check.has(EpisodeTextColumnKey.Text)
      || commands.check.has(EpisodeTextColumnKey.Description);
    if (isCheck) {
      return EpisodeTextEntityType.Check;
    }

    const isDialogue = !isEmpty(nodeOrSpeakerCleared) && !isEmpty(textCleared);
    if (isDialogue) {
      return EpisodeTextEntityType.Dialogue;
    }

    const isNarration = isEmpty(nodeOrSpeakerCleared) && !isEmpty(textCleared);
    if (isNarration) {
      return EpisodeTextEntityType.Narration;
    }

    return EpisodeTextEntityType.EmptyStep;
  }

  public static parseTextToEpisodeText(text: string): EpisodeTextImport | undefined {
    const episodeTextImport = new EpisodeTextImport();
    const rows = text.split('\n');
    const importRows = rows.map((row) => row.split('\t'));
    const firstIndex = importRows.findIndex((row) => EpisodeTextImportHelper.getRowType(row) === EpisodeTextEntityType.Node);
    if (firstIndex === -1) {
      return undefined;
    }

    let rowType: EpisodeTextEntityType = EpisodeTextEntityType.Skip;

    for (let rowIndex = firstIndex; rowIndex < importRows.length; rowIndex++) {
      const importRow = importRows[rowIndex] ?? [];

      rowType = EpisodeTextImportHelper.getRowType(importRow, rowType);

      switch (rowType) {
        case EpisodeTextEntityType.EmptyStep:
        case EpisodeTextEntityType.Skip:
          logger.log('[EpisodeTextImportUtils] Skip row', importRow);
          break;
        case EpisodeTextEntityType.Node: {
          const node = new EpisodeTextImportNode(importRow);
          episodeTextImport.addNode(node);
          break;
        }
        default: {
          const step = new EpisodeTextImportStep(importRow, rowType);
          episodeTextImport.addStep(step);
          break;
        }
      }
    }
    return episodeTextImport;
  }

  public static parseCommands(
    columnKey: EpisodeTextColumnKey,
    text: string,
    commands: TEpisodeTextImportCommands = new Map(),
  ):
    TEpisodeTextImportCommands {
    const newCommands = new Map(commands);
    Object.entries(EpisodeTextImportCommands).forEach(([key, value]) => {
      for (let valueIndex = 0; valueIndex < value.length; valueIndex++) {
        const command = value[valueIndex];

        const match = text.match(command.pattern);
        if (match) {
          const commandValue: TEpisodeTextImportCommandValue = newCommands.get(key as EpisodeTextImportCommandKey) ?? new Map();
          commandValue.set(columnKey, match);
          newCommands.set(key as EpisodeTextImportCommandKey, commandValue);
          break;
        }
      }
    });
    return newCommands;
  }

  public static getLowestYInNodes(nodes: Branch[]) {
    return nodes.reduce((acc, node) => {
      if (node.y > acc) {
        return node.y;
      }
      return acc;
    }, 0);
  }

  public static getStepTypeIdByEntityType(entityType: EpisodeTextEntityType | undefined): number | undefined {
    switch (entityType) {
      case EpisodeTextEntityType.Dialogue:
        return StepTypes.Dialogue;
      case EpisodeTextEntityType.Narration:
        return StepTypes.Narrator;
      case EpisodeTextEntityType.Choice:
        return StepTypes.Choice;
      case EpisodeTextEntityType.Chat:
        return StepTypes.Texting;
      case EpisodeTextEntityType.Thinking:
        return StepTypes.Thinking;
      case EpisodeTextEntityType.Reaction:
        return StepTypes.Reaction;
      case EpisodeTextEntityType.Remember:
        return StepTypes.Remember;
      case EpisodeTextEntityType.Check:
        return StepTypes.Check;
      default:
        return undefined;
    }
  }

  public static isNameExists(name: string, existingNames: string[], ifEmpty = true): boolean {
    if (isEmpty(name)) return ifEmpty;
    return existingNames.some((el) => el?.toLocaleLowerCase().trim() === name.toLocaleLowerCase().trim());
  }

  public static removeAllCommandsFromString(text: string) {
    let newText = text;
    Object.values(EpisodeTextImportCommands).forEach((commandType) => {
      commandType.forEach((command) => {
        newText = newText.replace(command.pattern, '');
      });
    });
    return newText.trim();
  }

  private static _calculateDepths = (tree: {[key: number]:number[]}, startNode: number): {[key: number]: number} => {
    const depths: { [key: number]: number} = {};

    function dfs(node: number, depth: number) {
      if (depths[node] === undefined) {
        depths[node] = depth;
        if (tree[node]) {
          tree[node].forEach((child) => dfs(child, depth + 1));
        }
      }
    }

    dfs(startNode, 0);
    return depths;
  };

  public static assignPositions = (
    tree: {[key: string]:number[]},
    startNode: number,
    initialPosition: {x: number, y: number} = { x: 0, y: 0 },
  ) => {
    const offsetX = 300;
    const offsetY = 350;
    const levelCounts: {[key: string]: number} = {};

    const depths: { [key: string]: number} = this._calculateDepths(tree, startNode);

    return Object.keys(depths)
      .reduce((acc, node) => {
        const depth = depths[node];
        if (!levelCounts[depth]) {
          levelCounts[depth] = 0;
        }
        const x = initialPosition.x + (levelCounts[depth] * offsetX);
        const y = initialPosition.y + (depth * offsetY);
        acc[node] = { x, y };
        levelCounts[depth] += 1;
        return acc;
      }, {} as {[key: string]: {x: number, y: number}});
  };

  public static convertCommandsToRecordType(commands: TEpisodeTextImportCommands): Record<EpisodeTextImportCommandKey, TEpisodeTextImportCommandValue> {
    return Object.values(EpisodeTextImportCommandKey)
      .reduce((acc, key) => {
        acc[key as EpisodeTextImportCommandKey] = commands.get(key as EpisodeTextImportCommandKey) ?? new Map();
        return acc;
      }, {} as Record<EpisodeTextImportCommandKey, TEpisodeTextImportCommandValue>);
  }

  public static getCheckEntityTypes(importCommands: TEpisodeTextImportCommands): 'single' | 'multi' | 'none' {
    const commands = EpisodeTextImportHelper.convertCommandsToRecordType(importCommands);
    const operationName = commands.check.values().next().value?.[2]?.toLocaleLowerCase().trim() ?? '';

    const isSingleCheck = operationName?.startsWith('if');
    if (isSingleCheck) {
      return 'single';
    }
    const isMultiCheck = ['min', 'max', 'lowest', 'highest'].includes(operationName);
    if (isMultiCheck) {
      return 'multi';
    }
    return 'none';
  }

  public static getSingleCheckCommandValues(importCommands: TEpisodeTextImportCommands) {
    const commands = EpisodeTextImportHelper.convertCommandsToRecordType(importCommands);
    const singleCheckTypeMemoryName = commands.check.values().next().value?.[3]?.toLocaleLowerCase().trim() ?? '';
    const singleCheckTypeOperator = commands.check.values().next().value?.[4]?.toLocaleLowerCase().trim() ?? '';
    const singleCheckTypeTextOperator = commands.check.values().next().value?.[5]?.toLocaleLowerCase().trim() ?? '';
    const singleCheckTypeValue = commands.check.values().next().value?.[6]?.trim().replace(/["']/g, '') ?? '';
    const singleCheckTypeTrueGoto = commands.check.values().next().value?.[8]?.toLocaleLowerCase().trim() ?? '';
    const singleCheckTypeFalseGoto = commands.check.values().next().value?.[10]?.toLocaleLowerCase().trim() ?? '';
    return {
      singleCheckTypeMemoryName,
      singleCheckTypeOperator,
      singleCheckTypeTextOperator,
      singleCheckTypeValue,
      singleCheckTypeTrueGoto,
      singleCheckTypeFalseGoto,
    };
  }

  public static getMultiCheckCommandValues(importCommands: TEpisodeTextImportCommands) {
    const commands = EpisodeTextImportHelper.convertCommandsToRecordType(importCommands);
    const multiCheckTypeFuncName = commands.check.values().next().value?.[2]?.toLocaleLowerCase().trim() ?? '';
    const multiCheckTypeMemoryTextNames = commands.check.values().next().value?.[5]?.toLocaleLowerCase().trim() ?? '';
    const multiCheckTypeGotoTextValues = commands.check.values().next().value?.[6]?.toLocaleLowerCase().trim() ?? '';

    const multiCheckTypeMemoryNames = multiCheckTypeMemoryTextNames.split(',')
      .map((el: string) => el.toLocaleLowerCase().trim());

    const gotoValues = multiCheckTypeGotoTextValues.split(',')
      .map((el: string) => {
        const match = el.match(/if\s+(\w*)\s+(?:goto\s+)?(\w*)/i);
        return {
          memory: match?.[1] ?? '',
          gotoNode: match?.[2] ?? '',
        };
      });
    const gotoValuesSorted = sortBy(gotoValues, (el) => multiCheckTypeMemoryNames.indexOf(el.memory));
    const multiCheckTypeGotoValues: string[] = gotoValuesSorted.map((el) => el.gotoNode) ?? [];

    return {
      multiCheckTypeFuncName,
      multiCheckTypeMemoryTextNames,
      multiCheckTypeGotoTextValues,
      multiCheckTypeMemoryNames,
      multiCheckTypeGotoValues,
    };
  }

  public static getInvalidValidCommand(commandString: string): string | undefined {
    const commandMatch = commandString.match(COMMAND_KEY_PATTERN);
    if (!commandMatch) {
      return undefined;
    }
    for (let i = 0; i < commandMatch.length; i++) {
      const commandType = Object.values(EpisodeTextImportCommands).flat();
      if (!commandType.some((command) => command.pattern.test(commandMatch[i]))) {
        return commandMatch[i];
      }
    }
    return undefined;
  }
}
