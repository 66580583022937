import { useFormik } from 'formik';
import { omit } from 'lodash-es';
import moment from 'moment';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as yup from 'yup';
import { ButtonAction, Event } from '../../../../../dorian-shared/types/event/Event';
import { DateTimeField } from '../../../../ui/_fields/DateTimeField/DateTimeField';
import { NumberField } from '../../../../ui/_fields/NumberField/NumberField';
import { TextareaFieldInput } from '../../../../ui/_fields/TextareaFieldInput/TextareaFieldInput';
import { TextField } from '../../../../ui/_fields/TextField/TextField';
import { FileInputValue, ImageInput } from '../ImageInput/ImageInput';
import { ButtonTargetInput } from './ButtonTargetInput/ButtonTargetInput';
import { prizeLabelMaxLength } from './DetailedPrizes/DetailedPrizeCard/DetailedPrizeCard';
import { DetailedPrizes, DetailedPrizesProps } from './DetailedPrizes/DetailedPrizes';
import { DetailedPrize } from './DetailedPrizes/DetailedPrizeType';

export enum PrizeType {
  Hearts = 'hearts',
  Sapphires = 'sapphires',
  None = ''
}

// eslint-disable-next-line no-template-curly-in-string
const requiredMessage = '${path} is required';
// eslint-disable-next-line no-template-curly-in-string
const maxMessage = '${path} should be up to ${max} characters';
// eslint-disable-next-line no-template-curly-in-string
const minMessage = '${path} should be at least ${min} characters';
const maxPrizeValueLength = 50;

function getEarliestEndTime(startTime: Date) {
  return moment(startTime).add(1, 'day').toDate();
}

const titleMaxLength = 50;
const descriptionMaxLength = 10000;
const labelMaxLength = 40;
const buttonTextMaxLength = 20;
const colorsMaxLength = 250;
const specialRequirementsMaxLength = 10000;
const authorLabelMaxLength = 50;
const schema = yup.object().shape({
  title: yup.string().label('Title').max(titleMaxLength, maxMessage).required(requiredMessage),
  description: yup.string().label('Description').min(10, minMessage).max(descriptionMaxLength, maxMessage)
    .required(requiredMessage),
  label: yup.string().label('Label').max(labelMaxLength, maxMessage),
  imageUrl: yup.string().label('Image').required(requiredMessage),
  specialRequirements: yup.string().label('Rules').min(10, minMessage).max(specialRequirementsMaxLength, maxMessage),
  authorLabel: yup.string().label('Author').max(authorLabelMaxLength, maxMessage),
  active: yup.bool(),
  priority: yup.number().label('Order').integer('Only whole numbers are allowed').min(1, minMessage)
    .required(requiredMessage),
  buttonText: yup.string().label('Button Text').max(buttonTextMaxLength, maxMessage).required(requiredMessage),
  buttonTarget: yup.string().label('Button Target').required(requiredMessage),
  prize: yup.object().shape({
    prizeType: yup.string(),
    prizeValue: yup.string(),
  }).test(
    'has-prize-value-if-hearts',
    "Prize value can't be empty if prize type selected",
    (prize) => (prize.prizeType === PrizeType.Hearts || prize.prizeType === PrizeType.Sapphires
      ? Boolean(prize.prizeValue)
      : true),
  ).test(
    'prize-value-is-too-long',
    `Prize Value should be up to ${maxPrizeValueLength}`,
    (prize) => {
      const length = prize.prizeValue?.length ?? 0;
      return length <= maxPrizeValueLength;
    },
  ),
  colors: yup.string().max(colorsMaxLength, maxMessage),
  startTime: yup.date().label('Start Time').required(requiredMessage),
  endTime: yup.date().label('End Time').required(requiredMessage).when(
    'startTime',
    (startTime, yupEndTime) => startTime && yupEndTime.min(getEarliestEndTime(startTime), 'Duration should be at least a day'),
  ),
  detailedEventPrizes: yup.array(yup.object().shape({
    label: yup.string().label('Event Prize`s label').min(10, minMessage).max(prizeLabelMaxLength, maxMessage)
      .required(requiredMessage),
    imageUrl: yup.string().label('Event Prize`s image').required(requiredMessage),
  })),
});

export interface EventCardPrize {
  prizeType: PrizeType
  prizeValue: string
}

function createEmptyCoverPrize() {
  return {
    prizeType: PrizeType.None,
    prizeValue: '',
  };
}

export interface EventFormValues extends Omit<Event, 'createdAt' | 'updatedAt' | 'id' | 'prize'> {
  id?: Event['id']
  prize: EventCardPrize | null
  newCoverImage?: FileInputValue
  detailedEventPrizes: DetailedPrize[]
}

export function createInitialFormValues(): EventFormValues {
  return {
    title: '',
    description: '',
    imageUrl: '',
    active: false,
    priority: 1,
    buttonText: '',
    colors: '',
    label: '',
    startTime: '',
    endTime: '',
    prize: createEmptyCoverPrize(),
    buttonAction: ButtonAction.Story,
    buttonTarget: '',
    specialRequirements: '',
    authorLabel: '',
    detailedEventPrizes: [],
  };
}

type NewOrExistingEvent = Omit<Event, 'createdAt' | 'updatedAt' | 'id'> & {
  id?: Event['id']
  createdAt?: Event['createdAt']
  updatedAt?: Event['updatedAt']
};

export function eventToFormValues(event: NewOrExistingEvent, detailedEventPrizes: DetailedPrize[]) {
  return {
    ...omit(event, ['createdAt', 'updatedAt', 'prize']),
    prize: event.prize ? JSON.parse(event.prize) : createEmptyCoverPrize(),
    detailedEventPrizes,
  };
}

export function createFormDataFromFormValues(values: Omit<EventFormValues, 'detailedEventPrizes'>) {
  const formData = new FormData();
  Object.entries(values).forEach(([key, value]) => {
    switch (key) {
      case 'prize':
        formData.append(key, JSON.stringify(value));
        break;
      case 'newCoverImage':
        if (value) {
          const fileInputValue = value as FileInputValue;
          formData.append('image', fileInputValue.file, fileInputValue.fileName);
        }
        break;
      case 'imageUrl':
        if (!values.newCoverImage) {
          formData.append(key, value as string);
        }
        break;
      default:
        formData.append(key, value as string);
    }
  });
  return formData;
}

interface EventFormProps {
  eventFormValues: EventFormValues
  isLoading: boolean
  onSubmit: (event: EventFormValues) => void
  submitButtonText: string
}

export function EventForm(props: EventFormProps) {
  const {
    eventFormValues,
    isLoading,
    onSubmit,
    submitButtonText,
  } = props;

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik<EventFormValues>({
    validationSchema: schema,
    initialValues: eventFormValues,
    onSubmit,
  });

  const handleImageChange = (fileInputValue: FileInputValue, dataURL: string) => {
    setValues((prevValues) => ({
      ...prevValues,
      imageUrl: dataURL,
      newCoverImage: fileInputValue,
    }));
  };

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Col md={12}>
          <TextField
            label="Event Title"
            name="title"
            placeholder="COMPETITION STARTS!"
            value={values.title}
            onChange={handleChange}
            error={touched.title ? errors.title : ''}
            maxLength={titleMaxLength}
          />
        </Col>
        <Col md={12}>
          <TextareaFieldInput
            rows={3}
            label="Event Description"
            placeholder="Our Vampire Insiders will already be fans of Anne..."
            name="description"
            value={values.description}
            onChange={handleChange}
            error={touched.description ? errors.description : ''}
            maxLength={descriptionMaxLength}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={6}>
          <TextField
            label="Event Label"
            name="label"
            placeholder="Label to display on event card (for example FEATURED)"
            value={values.label}
            onChange={handleChange}
            error={touched.label ? errors.label : ''}
            maxLength={labelMaxLength}
          />
        </Col>
        <Col md={6}>
          <TextField
            label="Author's Name"
            name="authorLabel"
            placeholder="author"
            value={values.authorLabel}
            onChange={handleChange}
            error={touched.authorLabel ? errors.authorLabel : ''}
            maxLength={authorLabelMaxLength}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={3}>
          <NumberField
            label="Order (bigger number - higher in the list)"
            name="priority"
            placeholder="order of events - events with bigger value shows first"
            min="1"
            step="1"
            value={values.priority}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <TextField
            label="Button Text"
            name="buttonText"
            placeholder="Text to be displayed inside CTA button"
            value={values.buttonText}
            onChange={handleChange}
            error={touched.buttonText ? errors.buttonText : ''}
            maxLength={buttonTextMaxLength}
          />
        </Col>
        <Col md={3}>
          <TextField
            label="Event Card Color"
            name="colors"
            placeholder="Color in format of #abc or linear-gradient(#abc, #def)"
            value={values.colors}
            onChange={handleChange}
            error={touched.colors ? errors.colors : ''}
            maxLength={colorsMaxLength}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={4}>
          <DateTimeField
            label="Start Time"
            value={values.startTime}
            onChange={(date: Date) => setFieldValue('startTime', date)}
            minDateTime={new Date()}
            error={touched.startTime ? errors.startTime : ''}
          />
        </Col>
        <Col md={4}>
          <DateTimeField
            label="End Time"
            value={values.endTime}
            onChange={(date: Date) => setFieldValue('endTime', date)}
            minDateTime={getEarliestEndTime(new Date(values.startTime))}
            error={touched.endTime ? errors.endTime : ''}
          />
        </Col>
        <Col md={4}>
          <TextField
            label="Rules"
            name="specialRequirements"
            placeholder="Rules of event"
            value={values.specialRequirements}
            onChange={handleChange}
            error={touched.specialRequirements ? errors.specialRequirements : ''}
            maxLength={specialRequirementsMaxLength}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={6}>

          <Form.Group>
            <Form.Label>Button type</Form.Label>
            <Form.Control
              value={values.buttonAction}
              as="select"
              required
              name="buttonAction"
              onChange={handleChange}
              isInvalid={!!errors.buttonAction}
            >
              <option value={ButtonAction.Story}>Story</option>
              <option value={ButtonAction.Chat}>Chat</option>

            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={6}>
          <ButtonTargetInput
            buttonAction={values.buttonAction}
            value={values.buttonTarget}
            onChange={(buttonTarget: string) => setFieldValue('buttonTarget', buttonTarget)}
            error={touched.buttonTarget ? errors.buttonTarget : ''}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={6}>
          <Form.Group>
            <Form.Label>Cover Prize Type</Form.Label>
            <Form.Control
              value={values.prize?.prizeType ?? ''}
              as="select"
              name="prize.prizeType"
              onChange={handleChange}
            >
              {Object.entries(PrizeType)
                .map(([key, value]) => (
                  <option key={key} value={value}>{value !== PrizeType.None ? value : 'None'}</option>
                ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={6}>
          <TextField
            label="Cover Prize value"
            name="prize.prizeValue"
            placeholder="Prize value - can be any text"
            value={values.prize?.prizeValue ?? ''}
            onChange={handleChange}
            error={touched.prize ? errors.prize : ''}
            maxLength={maxPrizeValueLength}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Check
            label="Active"
            name="active"
            checked={values.active}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <ImageInput
            initialURL={values.imageUrl}
            onConfirm={handleImageChange}
            error={touched.imageUrl ? errors.imageUrl : ''}
            imageCropperProps={{
              aspectRatio: 21 / 10,
            }}
            getBlobOptions={{
              croppedImageType: 'image/jpeg', // jpeg used here to make images files smaller size
              maxWidth: 3220,
              maxHeight: 1534,
              imageSmoothingQuality: 'high',
            }}
            accept="image/*"
          />
        </Col>
      </Row>
      <DetailedPrizes
        detailedPrizes={values.detailedEventPrizes}
        onChange={(detailedEventPrizes) => setFieldValue('detailedEventPrizes', detailedEventPrizes)}
        errors={errors.detailedEventPrizes as DetailedPrizesProps['errors']}
        touched={touched.detailedEventPrizes}
      />

      <Button type="submit">
        {isLoading && (
          <Spinner
            size="sm"
            animation="border"
          />
        )}
        {submitButtonText}
      </Button>
    </Form>
  );
}
