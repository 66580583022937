import React from 'react';
import noImageIcon from '../../../../assets/images/no_image.svg';
import stepsIcon from '../../../../assets/images/ui/icon-steps.svg';
import textIcon from '../../../../assets/images/ui/icon-text.svg';
import { isCheckStep } from '../BranchEdit/Steps/StepTypeCheck/StepTypeCheck';
import { BranchNodeCardCheckInfo } from './BranchNodeCardCheckInfo';
import { MemoryCards } from './MemoryCards/MemoryCards';
import { TraitImages } from './TraitImages';

export function BranchNodeCard({
  storyGroup,
  branchNode,
  memorySlotData,
}) {
  const isLocationImg = branchNode.location?.image
    && (storyGroup === 'active' || storyGroup === 'prompt');

  const decisionPoint = branchNode.links?.length > 0;
  const isCheck = isCheckStep(branchNode);

  return (
    <>
      <div
        className="locationImg"
        title={`${branchNode.location_title ?? '-'}`}
        style={{
          backgroundImage: `url(${branchNode.location?.image?.imageUrl ?? noImageIcon})`,
          backgroundSize: isLocationImg ? 'cover' : 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <p className="text-left m-0 pt-0 description descriptionLocationImg">
        {branchNode.description}
      </p>
      {branchNode.type !== 'choice' && (
      <MemoryCards steps={branchNode.steps} />
      )}
      <div className="d-flex w-100 justify-content-around">
        <div className="d-flex" title={`Steps: ${branchNode.steps_count || 0}`}>
          <img src={stepsIcon} alt="steps" />
          <div className="ml-1">
            {`${branchNode.steps_count || 0}`}
          </div>
        </div>
        <div className="d-flex" title={`Words: ${branchNode.words_count || 0}`}>
          <div className="d-flex">
            <img src={textIcon} alt="text" />
            <div className="ml-1">
              {`${branchNode.words_count || 0}`}
            </div>
          </div>
        </div>
      </div>
      {decisionPoint && (
        <TraitImages
          answers={branchNode.answers}
        />
      )}
      {isCheck && (
        <BranchNodeCardCheckInfo
          memorySlotData={memorySlotData}
        />
      )}
    </>
  );
}
