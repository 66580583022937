import cn from 'classnames';
import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { listColumnsSize } from '../AudiosBasicWithPick/constants';
import classNames from './AudioBasicWithPick.module.scss';

const convertDurationToTime = (value) => moment.utc(value * 1000).format('mm:ss');

export function AudioBasicWithPick({
  audio,
  isSelected,
  onClick,
}) {
  const handleClick = () => onClick(audio);

  return (
    <Row
      className={cn(classNames.item, { [classNames.selected]: isSelected })}
      onClick={handleClick}
    >
      <Col sm={listColumnsSize.title}>
        <Col className={classNames.fieldTitle}>
          {audio.title}
        </Col>
        <Col className={classNames.fieldAlias}>
          {audio.path}
        </Col>
      </Col>
      <Col sm={listColumnsSize.duration} className={classNames.fieldDuration}>
        {convertDurationToTime(audio.duration)}
      </Col>
      <Col sm={listColumnsSize.typeShort} className={classNames.fieldType}>
        {audio.type}
      </Col>
    </Row>
  );
}
