import { BranchStepMemoryActionType } from '../../../../../dorian-shared/types/branch/BranchStep';
import { MemoryShowIn } from '../../../Book/MemoryBank/memoryBankTypes';

export function convertNumber(num: number): string {
  if (num >= 1000 || num <= -1000) {
    return `${num < 0 ? '-' : '+'}${Math.floor(Math.abs(num) / 1000)}k`;
  }

  if (num > 0) {
    return `${num}`;
  }

  if (num < 0) {
    return `${num}`;
  }
  return `${num}`;
}

export function convertValue(input: number | string): string {
  if (typeof input === 'string') {
    return Number.isNaN(Number(input)) ? '' : convertNumber(Number(input));
  }

  return convertNumber(input);
}

export function gerValueByActionType(actionType: BranchStepMemoryActionType, value: string | number | Array<MemoryShowIn>, currentValue: string | number) {
  switch (actionType) {
    case BranchStepMemoryActionType.Set:
      return Number.isNaN(Number(value)) ? value : Number(value);
    case BranchStepMemoryActionType.Increase:
      return Number(currentValue) + Number(value);
    case BranchStepMemoryActionType.Decrease:
      return Number(currentValue) - Number(value);
    case BranchStepMemoryActionType.View:
      return currentValue;
    default:
      return value;
  }
}
