import async from 'async';
import { isEmpty } from 'lodash-es';
import { node } from 'prop-types';
import { useApiService } from '../../../../contexts/ApiServiceContext/ApiServiceContext';
import {
  useCharacterExpressionsContext,
} from '../../../../contexts/CharacterExpressionsContext/CharacterExpressionsContext';
import { BookLocation } from '../../../../dorian-shared/types/bookLocation/bookLocation';
import { AnswerStep, AnswerStepModifier, AnswerTypeId } from '../../../../dorian-shared/types/branch/AnswerStep';
import { Branch } from '../../../../dorian-shared/types/branch/Branch';
import { BranchStep, BranchStepMemoryActionType } from '../../../../dorian-shared/types/branch/BranchStep';
import { Character } from '../../../../dorian-shared/types/character/Character';
import { logger } from '../../../../services/loggerService/loggerService';
import { showToast } from '../../../ui/utils';
import { MemoryDTO, MemoryType, StepCheckSwitch } from '../../Book/MemoryBank/memoryBankTypes';
import { EpisodeTextImportHelper } from '../Models/EpisodeTextImportHelper';
import { EpisodeTextImportNode } from '../Models/EpisodeTextImportNode';
import { createNodeTree } from '../textImportUtils';
import { EpisodeTextImportCommandKey, TEpisodeTextImportCommands } from '../types/episodeTextImportCommandTypes';
import { crassCommandKeysExpressionsForStep } from '../types/EpisodeTextImportCrossCommands';
import { EpisodeTextEntityType } from '../types/episodeTextImportTypes';

export function useImportTextNodeManage(
  nodes: Branch[],
  bookLocations: BookLocation[] | null,
  episodeId: number,
  characters: Character[] | null,
  memoryBank: MemoryDTO[] | null,
) {
  const apiService = useApiService();
  const possibleExpressions = useCharacterExpressionsContext();

  const getExpressionId = (stepCommands: TEpisodeTextImportCommands) => {
    const commands = EpisodeTextImportHelper.convertCommandsToRecordType(stepCommands);
    const expression = crassCommandKeysExpressionsForStep.find((key) => commands[key].size > 0);
    return expression ? possibleExpressions.find((el) => el.value === expression)?.id : undefined;
  };

  const MAX_CONCURRENT_POST_REQUESTS = 5;
  const MAX_CONCURRENT_PUT_REQUESTS = 1;

  async function createEpisodeNodes(episodeTextImportNodes: EpisodeTextImportNode[]) {
    const lowestNodeY = EpisodeTextImportHelper.getLowestYInNodes(nodes);
    let nodePosition = {
      x: 1000,
      y: lowestNodeY + 350,
    };

    const createdNodes: Branch[] = [];
    const createNodePromises: (() => Promise<Partial<Branch>>)[] = [];

    for (let nodeIndex = 0; nodeIndex < episodeTextImportNodes.length; nodeIndex++) {
      const episodeTextImportNode = episodeTextImportNodes[nodeIndex];
      const nodeTitle = EpisodeTextImportHelper.removeAllCommandsFromString(episodeTextImportNode.title);
      const nodeDescription = EpisodeTextImportHelper.removeAllCommandsFromString(episodeTextImportNode.description);

      nodePosition = {
        ...nodePosition,
        y: nodePosition.y + 350,
      };

      const locationCommand = episodeTextImportNode.commands.get(EpisodeTextImportCommandKey.Location);
      const locationCommandValue = locationCommand?.values().next().value?.[2] ?? '';
      const locationNameNormalized = locationCommandValue.toLocaleLowerCase().trim();
      const location = bookLocations?.find(
        (el) => el.title.toLocaleLowerCase().trim() === locationNameNormalized
         || el.alias.toLocaleLowerCase().trim() === locationNameNormalized,
      );

      const isIntroNode = episodeTextImportNode.title.toLocaleLowerCase() === 'intro';
      if (isIntroNode) {
        const introNode = nodes.find((el) => el.title.toLocaleLowerCase() === 'intro');
        if (introNode) {
          const newIntroNode = {
            ...introNode,
            description: nodeDescription,
            locationId: introNode.locationId,
          };
          createdNodes.push(newIntroNode);
        }
      } else {
        const nodeToCreate = {
          number: 1,
          title: nodeTitle,
          description: nodeDescription,
          locationId: location?.id,
          ...nodePosition,
        };
        createNodePromises.push(async () => await apiService.createEpisodeNode(episodeId, nodeToCreate));
      }
    }
    try {
      const createNodeQueue = createNodePromises.map(
        (createNodePromise) => (callback: (error: unknown | null, result: Partial<Branch> | null) => void) => {
          createNodePromise()
            .then((response) => {
              callback(null, response);
            })
            .catch((error) => callback(error, null));
        },
      );
      const newNodes: Branch[] = await async.parallelLimit(createNodeQueue, MAX_CONCURRENT_POST_REQUESTS);
      createdNodes.push(...newNodes as Branch[]);
    } catch (error) {
      logger.error(`Error in creating branch: ${error}`);
      showToast({ textMessage: `Error in creating branch: ${error}` });
    }
    return createdNodes;
  }

  async function updateEpisodeNodes(createdNodes: Branch[], episodeTextImportNodes: EpisodeTextImportNode[]) {
    const updatedNodes: Branch[] = [];
    const updateNodesPromises: (() => Promise<Partial<Branch>>)[] = [];

    const lowestNodeY = EpisodeTextImportHelper.getLowestYInNodes(nodes);
    let firstUnlinkedNodePositionY = lowestNodeY + 350;
    const allNodes = [...nodes, ...createdNodes];

    for (let nodeIndex = 0; nodeIndex < createdNodes.length; nodeIndex++) {
      const createdNode = createdNodes[nodeIndex];
      const isLastNode = nodeIndex === createdNodes.length - 1;

      let nodeToUpdate: Partial<Branch> = {
        id: createdNode.id,
        title: createdNode.title,
        description: createdNode.description,
        locationId: createdNode.locationId,
      };
      let stepToCreate: Partial<BranchStep> = {};

      const nodeToCreate = episodeTextImportNodes.find(
        (el) => el.title.toLocaleLowerCase() === createdNode.title.toLocaleLowerCase(),
      );
      if (!nodeToCreate) {
        showToast({ textMessage: `Node not found: ${createdNode.title}` });
        logger.error(`[ImportTextModal] Node not found: ${createdNode.title}`);
        continue;
      }

      const { steps } = nodeToCreate;

      if (steps.length === 0 && !isLastNode) {
        const nextNode = createdNodes[nodeIndex + 1];
        nodeToUpdate = {
          ...nodeToUpdate,
          gotoBranchId: nextNode.id,
        };
      }

      const stepsToCreate: Partial<BranchStep>[] = [];

      for (let stepIndex = 0; stepIndex < steps.length; stepIndex++) {
        const step = steps[stepIndex];
        const isLastStep = stepIndex === steps.length - 1;

        const stepTypeId = EpisodeTextImportHelper.getStepTypeIdByEntityType(step.type);
        const speakerName = EpisodeTextImportHelper.removeAllCommandsFromString(step.speakerName);
        const speakerText = EpisodeTextImportHelper.removeAllCommandsFromString(step.speakerText);

        switch (step.type) {
          case EpisodeTextEntityType.GotoFromNode: {
            const gotoNodeCommand = step.commands.get(EpisodeTextImportCommandKey.Goto);
            const toNodeTitle = String(gotoNodeCommand?.values().next().value?.[2] ?? '');

            const toNode = allNodes.find((el) => el.title?.toLocaleLowerCase() === toNodeTitle?.toLocaleLowerCase());
            if (!toNode) {
              logger.error('[ImportTextModal] To node not found');
              continue;
            }
            nodeToUpdate = {
              ...nodeToUpdate,
              gotoBranchId: toNode.id,
            };
            continue;
          }
          case EpisodeTextEntityType.Chat: {
            const commands = EpisodeTextImportHelper.convertCommandsToRecordType(step.commands);
            const stepCharacterName = speakerName.toLocaleLowerCase();
            const character = characters?.find((el) => el.alias.toLocaleLowerCase() === stepCharacterName);
            stepToCreate = {
              ...stepToCreate,
              stepTypeId,
              text: speakerText,
              characterId: character?.id,
              characterExpressionId: commands.anxious.size > 0
                ? possibleExpressions.find((el) => el.value === EpisodeTextImportCommandKey.ExpressionAnxious)?.id
                : undefined,
            };
            break;
          }
          case EpisodeTextEntityType.Dialogue: {
            const stepCharacterName = speakerName.toLocaleLowerCase();
            const character = characters?.find((el) => el.alias.toLocaleLowerCase() === stepCharacterName);
            stepToCreate = {
              ...stepToCreate,
              stepTypeId,
              text: speakerText,
              characterId: character?.id,
              characterExpressionId: getExpressionId(step.commands),
            };
            break;
          }
          case EpisodeTextEntityType.Choice: {
            const stepCharacterName = speakerName.toLocaleLowerCase();
            const character = characters?.find((el) => el.alias.toLocaleLowerCase() === stepCharacterName);

            const answers: Partial<AnswerStep>[] = [];
            let answerStepIndex = stepIndex + 1;
            let nextStepAnswer = steps[answerStepIndex];

            while (nextStepAnswer?.type === EpisodeTextEntityType.Answer) {
              const answerText = EpisodeTextImportHelper.removeAllCommandsFromString(nextStepAnswer.speakerText);
              const gotoNodeCommand = nextStepAnswer.commands.get(EpisodeTextImportCommandKey.Goto);
              const gotoBranchName = gotoNodeCommand?.values().next().value?.[2];
              const gotoBranchId = gotoBranchName
                ? allNodes.find((el) => el.title?.toLocaleLowerCase() === gotoBranchName?.toLowerCase())?.id
                : undefined;

              const isPremiumAnswer = nextStepAnswer.commands.has(EpisodeTextImportCommandKey.PremiumAnswer);
              const premiumAnswerCommand = nextStepAnswer.commands.get(EpisodeTextImportCommandKey.PremiumAnswer);
              const answerPrice = premiumAnswerCommand?.values().next().value?.[2] ?? 0;

              const isBonusContent = nextStepAnswer.commands.has(EpisodeTextImportCommandKey.BonusContent);
              const bonusModifier: AnswerStepModifier | undefined = isBonusContent
                ? { answerModifierTypeId: 1 }
                : undefined;

              answers.push({
                text: answerText,
                answerTypeId: isPremiumAnswer ? AnswerTypeId.Paid : AnswerTypeId.Free,
                answerPrice: Number(answerPrice),
                gotoBranchId,
                modifiers: bonusModifier ? [bonusModifier] : undefined,
              });
              answerStepIndex += 1;
              nextStepAnswer = steps[answerStepIndex];
            }
            stepIndex = answerStepIndex - 1;
            const choiceText = EpisodeTextImportHelper.removeAllCommandsFromString(step.speakerText);
            stepToCreate = {
              ...stepToCreate,
              stepTypeId,
              text: choiceText,
              characterId: character?.id,
              characterExpressionId: getExpressionId(step.commands),
              answers: answers as AnswerStep[],
            };
            stepsToCreate.push(stepToCreate);
            continue;
          }
          case EpisodeTextEntityType.Answer: {
            logger.error(`[ImportTextModal] Answer step can be implemented in Choice step: ${step.speakerText}`);
            break;
          }
          case EpisodeTextEntityType.Thinking:
          case EpisodeTextEntityType.Reaction: {
            const stepCharacterName = speakerName.toLocaleLowerCase();
            const character = characters?.find((el) => el.alias.toLocaleLowerCase() === stepCharacterName);
            stepToCreate = {
              ...stepToCreate,
              stepTypeId,
              text: speakerText,
              characterId: character?.id,
              characterExpressionId: getExpressionId(step.commands),
            };
            break;
          }
          case EpisodeTextEntityType.Remember: {
            const rememberCommand = step.commands.get(EpisodeTextImportCommandKey.StepRemember);
            if (!rememberCommand || rememberCommand.size === 0) {
              logger.error(`[ImportTextModal] Remember command not found: ${node.name}`);
              break;
            }
            const variableName = rememberCommand.values().next().value[2]?.toLocaleLowerCase().trim();
            const operator = rememberCommand.values().next().value[3]?.toLocaleLowerCase().trim();
            const variableValue = rememberCommand.values().next().value[4]?.trim();
            const memory = memoryBank?.find((el) => el.name.toLocaleLowerCase().trim() === variableName);
            if (!memory) {
              showToast({ textMessage: `Variable not found: ${variableName}` });
              logger.error(`[ImportTextModal] Variable not found: ${variableName}`, memoryBank);
            }

            const variableId = memory?.id ?? 0;
            const variableType = memory?.type;
            const value = variableType === MemoryType.Boolean ? String(variableValue.toLocaleLowerCase() === 'yes') : variableValue;

            switch (operator) {
              case '+':
                stepToCreate = {
                  ...stepToCreate,
                  stepTypeId,
                  action: {
                    variableId,
                    type: BranchStepMemoryActionType.Increase,
                    value,
                  },
                };
                break;
              case '-':
                stepToCreate = {
                  ...stepToCreate,
                  stepTypeId,
                  action: {
                    variableId,
                    type: BranchStepMemoryActionType.Decrease,
                    value,
                  },
                };
                break;
              case '=':
                stepToCreate = {
                  ...stepToCreate,
                  stepTypeId,
                  action: {
                    variableId,
                    type: BranchStepMemoryActionType.Set,
                    value,
                  },
                };
                break;
              default:
                showToast({ textMessage: `Remember command action type not found: ${node.name}` });
                logger.error(`[ImportTextModal] Remember command action type not found: ${node.name}`);
                break;
            }
            break;
          }
          case EpisodeTextEntityType.Check: {
            const checkCommand = step.commands.get(EpisodeTextImportCommandKey.StepCheck);
            if (!checkCommand || checkCommand.size === 0) {
              logger.error(`[ImportTextModal] Check command not found: ${node.name}`);
              break;
            }
            if (!step.check || !step.switch) {
              showToast({ textMessage: `Check not found: ${node.name}` });
              logger.error(`[ImportTextModal] Check not found: ${node.name}`);
              break;
            }
            const checkEntityType = EpisodeTextImportHelper.getCheckEntityTypes(step.commands);
            switch (checkEntityType) {
              case 'single': {
                const {
                  singleCheckTypeTrueGoto,
                  singleCheckTypeFalseGoto,
                } = EpisodeTextImportHelper.getSingleCheckCommandValues(step.commands);

                const trueGoto = allNodes.find((el) => el.title?.toLocaleLowerCase().trim() === singleCheckTypeTrueGoto);
                const falseGoto = allNodes.find((el) => el.title?.toLocaleLowerCase().trim() === singleCheckTypeFalseGoto);

                const stepSwitch: StepCheckSwitch[] = step.switch.map((el) => {
                  if (el.gotoBranchId < 1) {
                    return {
                      value: el.value,
                      gotoBranchId: el.value && trueGoto ? trueGoto.id : falseGoto?.id ?? -1,
                    };
                  }
                  return el;
                });

                stepToCreate = {
                  ...stepToCreate,
                  stepTypeId,
                  check: step.check,
                  switch: stepSwitch,
                };
                stepsToCreate.push(stepToCreate);
                continue;
              }
              case 'multi': {
                const {
                  multiCheckTypeGotoValues,
                } = EpisodeTextImportHelper.getMultiCheckCommandValues(step.commands);

                const stepSwitch: StepCheckSwitch[] = step.switch.map((el, index) => {
                  if (el.gotoBranchId < 1) {
                    const gotoBranch = allNodes.find((createdNodeEl) => createdNodeEl.title.toLocaleLowerCase().trim() === multiCheckTypeGotoValues[index]);
                    return {
                      value: el.value,
                      gotoBranchId: gotoBranch?.id ?? -1,
                    };
                  }
                  return el;
                });

                stepToCreate = {
                  ...stepToCreate,
                  stepTypeId,
                  check: step.check,
                  switch: stepSwitch,
                };
                stepsToCreate.push(stepToCreate);
                continue;
              }
              default:
                break;
            }
            break;
          }

          default:
            stepToCreate = {
              ...stepToCreate,
              stepTypeId,
              text: speakerText,
            };
            break;
        }

        const gotoNodeCommand = step.commands.get(EpisodeTextImportCommandKey.Goto);

        if (gotoNodeCommand) {
          const toNodeTitle = gotoNodeCommand.values().next().value?.[2];
          const toNode = allNodes.find((el) => el.title === toNodeTitle);
          if (!toNode) {
            logger.error('[ImportTextModal] To node not found');
            continue;
          }
          nodeToUpdate = {
            ...nodeToUpdate,
            gotoBranchId: toNode.id,
          };
        } else if (isLastStep && !isLastNode) {
          const nextNode = createdNodes[nodeIndex + 1];
          nodeToUpdate = {
            ...nodeToUpdate,
            gotoBranchId: nextNode.id,
          };
        }

        if (!isEmpty(stepToCreate)) {
          stepsToCreate.push(stepToCreate);
        }
      }

      if (stepsToCreate.length > 0) {
        nodeToUpdate = {
          ...nodeToUpdate,
          steps: stepsToCreate as BranchStep[],
        };
      }

      /*
        Reposition nodes by tree
       */
      if (nodeToUpdate.title?.toLowerCase() !== 'intro') {
        const nodesToReposition = [...updatedNodes, nodeToUpdate] as Branch[];
        const tree = createNodeTree(nodesToReposition);
        const positions = EpisodeTextImportHelper.assignPositions(tree, nodesToReposition[0].id, {
          x: 0,
          y: lowestNodeY + 350,
        });
        if (nodeToUpdate.id) {
          const position = positions[nodeToUpdate.id];
          if (position) {
            nodeToUpdate = {
              ...nodeToUpdate,
              ...position,
            };
          } else {
            nodeToUpdate = {
              ...nodeToUpdate,
              y: firstUnlinkedNodePositionY,
            };
            firstUnlinkedNodePositionY += 350;
          }
        }
      }
      updateNodesPromises.push(async () => await apiService.updateEpisodeNode(episodeId, createdNode.id, { ...nodeToUpdate }));
      updatedNodes.push(nodeToUpdate as Branch);
    }

    try {
      const updatedNodesPromises = updateNodesPromises.map(
        (updateNodePromise) => (callback: (error: unknown | null, result: Partial<Branch> | null) => void) => {
          updateNodePromise()
            .then((response) => {
              callback(null, response);
            })
            .catch((error) => callback(error, null));
        },
      );
      await async.parallelLimit(updatedNodesPromises, MAX_CONCURRENT_PUT_REQUESTS);
    } catch (error) {
      logger.error(`Error in updating branches: ${error}`);
      showToast({ textMessage: `Error in updating branches: ${error}` });
    }

    return updatedNodes;
  }

  return { createEpisodeNodes, updateEpisodeNodes };
}
