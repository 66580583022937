import { useMemoryBankContext } from '../../../../../contexts/MemoryBankContext/MemoryBankContext';
import { useMemoryIconsContext } from '../../../../../contexts/MemoryIconsContext/MemoryIconsContext';
import { BranchStep, BranchStepMemoryActionType } from '../../../../../dorian-shared/types/branch/BranchStep';
import { MemoryType, MemoryValue } from '../../../Book/MemoryBank/memoryBankTypes';
import { StepWithAction } from '../MemoryCard/MemoryCardTypes';
import { gerValueByActionType } from '../MemoryCard/utils';

export function useStepsWithAction(steps: BranchStep[]) {
  const { memories } = useMemoryBankContext();
  const { memoryIcons } = useMemoryIconsContext();

  return steps.reduce((acc: StepWithAction[], step) => {
    if (step.action) {
      const memory = memories.find((m) => Number(m.id) === Number(step.action?.variableId ?? 0));
      const icon = memoryIcons.find((m) => Number(m.id) === Number(memory?.icon ?? 0));
      const isMemoryNumeric = memory?.type === MemoryType.Number;
      const isValueArray = Array.isArray(step.action.value);

      const memoryExistsIndex = acc.findIndex((m) => m.memoryId === memory?.id);
      let { value } = step.action;
      value = isMemoryNumeric && !isValueArray ? Number(value) : value;
      const isToggleShowIn = step.action.type === BranchStepMemoryActionType.View;
      const currentValue = acc[memoryExistsIndex]?.value ?? '';
      value = gerValueByActionType(step.action.type, value, currentValue);

      if (memoryExistsIndex > -1) {
        acc[memoryExistsIndex] = {
          ...acc[memoryExistsIndex],
          value: value as MemoryValue,
          isToggleShowIn: isToggleShowIn || acc[memoryExistsIndex].isToggleShowIn,
        };
        return acc;
      }

      acc.push({
        id: step.id,
        type: 'card',
        memoryId: memory?.id ?? 0,
        memoryName: memory?.name ?? '',
        memoryIconUrl: icon?.url ?? '',
        value: isToggleShowIn ? '0' : value as MemoryValue,
        isToggleShowIn,
      });
    }
    return acc;
  }, []);
}
