import classNames from 'classnames/bind';
import React, { PureComponent } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  CurrencyToggleSettings,
} from '../../../../../dorian-shared/types/currencyToggleSettings/CurrencyToggleSettings';
import { NodeEditContext } from '../context/NodeEditContext';
import styles from './Steps.scss';
import { getCurrencyToggleSettingsIcon } from './utils';

const cs = classNames.bind(styles);

function renderOptionsList(currencyToggleSettings = CurrencyToggleSettings.HeartsFirst) {
  return [...Array(42).keys()].map((i) => {
    // 0-20 in steps of 1, 25-50 in steps of 5, 60-200 in steps of 10. 42 entries total.
    // eslint-disable-next-line no-nested-ternary
    const price = i > 20 ? i > 26 ? 50 + 10 * (i - 26) : 20 + 5 * (i - 20) : i;
    const willShowLegacyIcon = currencyToggleSettings === CurrencyToggleSettings.SapphiresFirst
    || currencyToggleSettings === CurrencyToggleSettings.HeartsFirst
    || currencyToggleSettings === CurrencyToggleSettings.HeartsOnly
    || currencyToggleSettings === undefined
    || currencyToggleSettings === null;
    const icon = willShowLegacyIcon ? '♥' : '';
    return <option value={price} key={price}>{price ? `${price} ${icon}` : '--'}</option>;
  });
}

export class AnswerPrice extends PureComponent {
  render() {
    const {
      answerIndex, disabled, answerPrice, activeStepsFunc, handleChange, xs, stepIndex, xl, sm, lg, currencyToggleSettings,
    } = this.props;
    return (
      <Form.Group
        xs={xs || 12}
        sm={sm || 5}
        lg={lg || 4}
        xl={xl || 4}
        as={Col}
        controlId={`answerPrice${answerIndex}`}
        className={cs('mb-0')}
      >
        <Row>
          <Form.Label
            className="text-right text-sm-left px-1"
            column
            sm={5}
            xl={4}
          >
            Price
          </Form.Label>
          <Col className={cs('mb-0 px-1')}>
            <Form.Control
              disabled={disabled}
              size="sm"
              as="select"
              value={answerPrice || 0}
              name={`steps[${stepIndex}][answers][${answerIndex}][answerPrice]`}
              onFocus={() => {
                if (activeStepsFunc) {
                  activeStepsFunc(`steps-${stepIndex}-answers-${answerIndex}-answerPrice`);
                }
              }}
              onMouseDown={(e) => e.stopPropagation()}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
            >
              <option value={null} />
              {renderOptionsList(currencyToggleSettings)}
            </Form.Control>
          </Col>
        </Row>
      </Form.Group>
    );
  }
}
