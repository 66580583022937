import classNames from 'classnames/bind';
import React, { PureComponent } from 'react';
import {
  Button, Card, Dropdown, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';
import { BranchContent } from './BranchContent';
import { ChangeColor } from './ChangeColor';
import { ConnectionButton } from './ConnectionButton';
import { DeleteBranch } from './DeleteBranch';
import { EditName } from './EditName';
import styles from './StoryBranches.scss';

const cs = classNames.bind(styles);

export class BranchItem extends PureComponent {
  constructor(props, ...args) {
    super(props, ...args);
    const { branchNode, newBranchId } = this.props;

    this.brancheNameForm = React.createRef();
    this.refFormControl = React.createRef();
    const editName = !!(newBranchId && newBranchId === branchNode.id);
    this.state = {
      modalDeleteBranchShow: false,
      editName,
      isLoading: false,
    };
  }

  componentDidMount() {
    const { editName } = this.state;
    const { onEdit } = this.props;
    if (editName) {
      this.startEditName();
      onEdit?.(editName);
    }
  }

  componentWillUnmount() {
    this.stopEditName();
  }

  componentDidUpdate(prevProps, prevState) {
    const { editName } = this.state;
    const { onEdit } = this.props;

    if (prevState.editName !== editName) {
      if (editName) {
        this.startEditName();
      } else {
        this.stopEditName();
      }

      if (onEdit) {
        onEdit(editName);
      }
    }
  }

  handleClickOutside = (event) => {
    const { editName } = this.state;
    const area = this.refFormControl.current;
    if (area && !area.contains(event.target) && editName) {
      this.brancheNameForm.current.click();
    }
  };

  escFunction = (event) => {
    const { editName } = this.state;

    if (event.keyCode === 27 && editName) {
      this.setState({
        editName: false,
      });
    }
  };

  stopEditName = () => {
    document.removeEventListener('click', this.handleClickOutside, false);
    document.removeEventListener('keydown', this.escFunction, false);
  };

  startEditName = () => {
    document.addEventListener('click', this.handleClickOutside, { passive: true });
    document.addEventListener('keydown', this.escFunction, { passive: true });
  };

  updateBranch = (arr) => {
    const { branchNode, storyId, onError } = this.props;

    this.setState({ isLoading: true });

    api.patch(`/v1/stories/${storyId}/branches/${branchNode.id}`, arr)
      .then(() => {
        this.update();
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
      })
      .finally(() => {
        this.setState({
          editName: false,
          isLoading: false,
        });
      });
  };

  isIntro = () => {
    const { branchNode } = this.props;

    return branchNode.title === 'intro';
  };

  clearSelection = () => {
    const { clearSelection, branchNode } = this.props;

    if (clearSelection) {
      clearSelection([branchNode.id]);
    }
  };

  update = () => {
    const { update, branchNode } = this.props;

    if (update) {
      update(branchNode);
    }
  };

  handleDelete = () => {
    const { onDeleteSuccess } = this.props;
    onDeleteSuccess();
    this.setState({ modalDeleteBranchShow: false });
  };

  render() {
    const {
      disabled,
      duplicate,
      branchNode,
      selectCascade,
      storyId,
      storyGroup,
      actionEditBranch,
      isAnalyticsVisible,
      isCheckStep,
      memorySlotData,
      isLastEdit,
    } = this.props;

    const {
      editName,
      modalDeleteBranchShow,
      modalChangeColorBranchShow,
      isLoading,
    } = this.state;

    const decisionPoint = branchNode.links && branchNode.links.length > 0;

    const modalChangeColorBranchClose = () => {
      this.setState({ modalChangeColorBranchShow: false });
      this.update();
    };

    const changeColorBranch = () => {
      if (modalChangeColorBranchShow !== true) {
        return null;
      }

      return (
        <ChangeColor
          storyId={storyId}
          show={modalChangeColorBranchShow}
          onHide={modalChangeColorBranchClose}
          obj={branchNode}
        />
      );
    };

    const cardBodyStyle = branchNode.bgColor ? { backgroundColor: branchNode.bgColor } : {};

    return (
      <Card
        className={cs(
          'text-center m-1 branchesItems branchesItemsLong',
          this.isIntro() ? 'branch_intro' : `branch_${branchNode.type}`,
          branchNode.bonus_content ? 'branch_gold' : null,
          editName ? 'branchEdit' : null,
          decisionPoint || isCheckStep ? 'branchesDecsionPoint' : null,
          isCheckStep ? 'branch_check' : null,
          isLastEdit ? 'lastEdit' : null,
        )}
      >
        <Card.Body style={cardBodyStyle}>
          <Card.Title
            className="mb-1 p-0"
            onDoubleClick={(e) => {
              if (!disabled && !this.isIntro()) {
                e.stopPropagation();
                this.setState({
                  editName: true,
                });
              }
            }}
          >
            {!isLoading && !editName && (
              <span>{branchNode.title}</span>
            ) }
            {!isLoading && editName && (
              <EditName
                {...this.props}
                refFormControl={this.refFormControl}
                brancheNameForm={this.brancheNameForm}
                handleSubmit={this.updateBranch}
              />
            )}
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
                <div className="editNameBg" />
              </div>
            )}
            {decisionPoint ? 'Decision Point' : null}
          </Card.Title>

          <Card.Text
            as="div"
            className={cs('cardBranchContent', isAnalyticsVisible ? 'showAnalytic' : null)}
          >
            <BranchContent
              storyGroup={storyGroup}
              branchNode={branchNode}
              isAnalyticsVisible={isAnalyticsVisible}
              memorySlotData={memorySlotData}
            />
          </Card.Text>

        </Card.Body>
        <Card.Footer className="not-selectable">
          {(!isAnalyticsVisible || !disabled) && (
            <ConnectionButton
              {...this.props}
              disabled={disabled}
            />
          )}

          <Button
            type="reset"
            variant="outline-dark"
            size="sm"
            className="mx-1"
            onClick={() => {
              this.clearSelection();
              if (actionEditBranch) {
                actionEditBranch(branchNode);
              }
            }}
          >
            Edit
          </Button>

          {(!isAnalyticsVisible || disabled) && (
            <Dropdown
              className="mx-1 d-inline-block"
              as="div"
            >
              <Dropdown.Toggle
                variant="outline-dark"
                id="ellipsis"
                className="mx-1"
                size="sm"
              >
                ...
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  disabled={disabled}
                  onClick={() => {
                    this.setState({ modalChangeColorBranchShow: true });
                  }}
                >
                  Color
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={disabled}
                  onClick={() => {
                    duplicate([branchNode.id]);
                  }}
                >
                  Duplicate
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={disabled}
                  onClick={() => {
                    selectCascade(branchNode.id);
                  }}
                >
                  Select cascade
                </Dropdown.Item>

                <Dropdown.Divider />
                <Dropdown.Item
                  disabled={disabled || this.isIntro()}
                  onClick={() => {
                    this.setState({ modalDeleteBranchShow: true });
                  }}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <DeleteBranch
            show={modalDeleteBranchShow}
            storyId={storyId}
            branches={[branchNode]}
            onDelete={this.handleDelete}
            onCancel={() => this.setState({ modalDeleteBranchShow: false })}
          />
          {changeColorBranch()}
        </Card.Footer>
      </Card>
    );
  }
}
