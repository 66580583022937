import { isEmpty } from 'lodash-es';
import { CurrencyToggleSettings } from '../../../../../dorian-shared/types/currencyToggleSettings/CurrencyToggleSettings';
import { MemoryShowIn } from '../../../Book/MemoryBank/memoryBankTypes';
import { MemoryBank } from '../../../BookEconomyPage/economics/bookEconomy';

export const SCROLLABLE_STEP_FORM_ELEMENT_ID = 'step_form_scrollable_element';

export function count(array: unknown[]): number {
  let c = 0;
  for (let i = 0; i < array.length; i++) {
    if (typeof array[i] !== 'undefined') {
      c += 1;
    }
  }
  return c;
}

export const checkUsedMemoriesInText = (text: string, memories: MemoryBank[]): string[] => {
  const matches = text.matchAll(/\{([^}]*)\}/g);
  const errors: string[] = [];
  for (const match of matches) {
    const memoryName = match[1];
    const memorySlot = memories.find((memory) => memory.name === memoryName);
    if (!memorySlot) {
      errors.push(memoryName);
    }
  }
  return errors;
};

export const getShowInSelectOptions = (): { value: MemoryShowIn, label: string }[] => Object.values(MemoryShowIn)
  .filter((showInValue) => showInValue !== MemoryShowIn.Hide)
  .map((showInValue) => ({
    value: showInValue,
    label: showInValue.toString(),
  }));

export const getShowInSelectValue = (value: MemoryShowIn[]): { value: MemoryShowIn, label: string }[] => {
  if (isEmpty(value)) {
    return [];
  }

  if (!Array.isArray(value)) {
    return [];
  }

  return value.filter((v) => v !== MemoryShowIn.Hide).map((v) => ({
    value: v,
    label: v.toString(),
  }));
};

export const getCurrencyToggleSettingsIcon = (currencyToggleSettings: CurrencyToggleSettings): string => {
  switch (currencyToggleSettings) {
    case CurrencyToggleSettings.HeartsFirst:
      return '❤️💎';
    case CurrencyToggleSettings.SapphiresFirst:
      return '💎❤️';
    case CurrencyToggleSettings.HeartsOnly:
      return '❤️';
    case CurrencyToggleSettings.SapphiresOnly:
      return '💎';
    default:
      return '';
  }
};

export const getCurrencyToggleSettingsLabel = (currencyToggleSettings: CurrencyToggleSettings): string => {
  switch (currencyToggleSettings) {
    case CurrencyToggleSettings.HeartsFirst:
      return `${getCurrencyToggleSettingsIcon(currencyToggleSettings)} Hearts First`;
    case CurrencyToggleSettings.SapphiresFirst:
      return `${getCurrencyToggleSettingsIcon(currencyToggleSettings)} Sapphires First`;
    case CurrencyToggleSettings.HeartsOnly:
      return `${getCurrencyToggleSettingsIcon(currencyToggleSettings)} Hearts Only`;
    case CurrencyToggleSettings.SapphiresOnly:
      return `${getCurrencyToggleSettingsIcon(currencyToggleSettings)} Sapphires Only`;
    default:
      return '';
  }
};

export const getCurrencyToggleSettingsOptions = () => Object.values(CurrencyToggleSettings).map((item: CurrencyToggleSettings) => ({
  value: item,
  label: getCurrencyToggleSettingsLabel(item),
}));
