import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import '../../ValidatePanel.scss';
import { Panel } from '../index';
import { BranchesListPanelForm } from './BranchesListPanelForm';
import { BranchListPanelOptions } from './BranchesListPanelTypes';
import { BranchesListPass } from './BranchesListPass';

export const branchListPanelOptions = [
  { value: BranchListPanelOptions.AllNodes, label: 'All Nodes' },
  { value: BranchListPanelOptions.DecisionPoints, label: 'Decision Points' },
  { value: BranchListPanelOptions.ChapterPass, label: 'Episode Pass' },
  { value: BranchListPanelOptions.Remember, label: 'Remember' },
];

export class BranchesListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activeKey: 'allNodes',
    };
  }

  loadingFunc = (e) => {
    this.setState({
      loading: e,
    });
  };

  render() {
    const { show, onHide } = this.props;
    const { loading, activeKey } = this.state;

    return (
      <Panel
        pageTitle=""
        show={show}
        onHide={onHide}
        loading={loading}
      >
        <div className="infoPanelContent">
          <div
            className="BranchesListBox"
            style={{ paddingTop: 0 }}
          >
            <Row className="BranchesListFilterBox">
              <Col sm="auto" className="BranchesListFilterLabel">
                Filter by:
              </Col>
              <Col>
                <Select
                  label="Filter by"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  defaultValue={branchListPanelOptions.find((e) => e.value === activeKey) ?? branchListPanelOptions[0]}
                  isDisabled={loading}
                  isLoading={loading}
                  isClearable={false}
                  name="branchListPanelOptions"
                  options={branchListPanelOptions}
                  onChange={(e) => {
                    this.setState({
                      activeKey: e.value,
                    });
                  }}
                  styles={{
                    container: (baseStyles) => ({
                      ...baseStyles,
                      border: '1px solid #ff6251',
                    }),
                  }}
                />
              </Col>
            </Row>

            {(activeKey === BranchListPanelOptions.AllNodes) && (
            <BranchesListPanelForm
              loadingFunc={this.loadingFunc}
              disabledRow
              sortType="name"
              {...this.props}
            />
            )}
            {(activeKey === BranchListPanelOptions.DecisionPoints) && (
              <BranchesListPanelForm
                type="choice"
                loadingFunc={this.loadingFunc}
                disabledRow={false}
                analytics
                {...this.props}
              />
            )}
            {(activeKey === BranchListPanelOptions.ChapterPass) && (
            <BranchesListPass
              loadingFunc={this.loadingFunc}
              disabledRow
              {...this.props}
            />
            )}
            {(activeKey === BranchListPanelOptions.Remember) && (
            <BranchesListPanelForm
              type="remember"
              loadingFunc={this.loadingFunc}
              disabledRow
              {...this.props}
            />
            )}
          </div>
        </div>
      </Panel>
    );
  }
}
