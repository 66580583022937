import React from 'react';
import { BranchStep } from '../../../../../dorian-shared/types/branch/BranchStep';
import { MemoryCard } from '../MemoryCard/MemoryCard';
import css from './MemoryCards.module.scss';
import { useStepsWithAction } from './useStepsWithAction';

interface MemoryCardsProps {
  steps: BranchStep[];
}

export function MemoryCards(props: MemoryCardsProps) {
  const { steps } = props;
  const stepsWithAction = useStepsWithAction(steps);

  if (stepsWithAction.length > 5) {
    const totalCards = stepsWithAction.length - 4;
    stepsWithAction.splice(4, totalCards, {
      id: 0,
      type: 'text',
      memoryId: 0,
      memoryName: 'More',
      memoryIconUrl: '',
      value: totalCards,
      isToggleShowIn: false,
    });
  }

  return (
    <div className={css.memoryCardsContainer}>
      {stepsWithAction.map((stepWithAction) => (
        <MemoryCard
          key={stepWithAction.id}
          stepWithAction={stepWithAction}
        />
      ))}
    </div>
  );
}
