import React from 'react';
import noImage from '../../../../../assets/images/no_image.svg';
import css from './MemoryCard.module.scss';
import { StepWithAction } from './MemoryCardTypes';
import { convertValue } from './utils';

interface MemoryCardProps {
  stepWithAction: StepWithAction;
}

export function MemoryCard(props: MemoryCardProps) {
  const { stepWithAction } = props;
  const {
    memoryIconUrl, value, memoryName, type, isToggleShowIn,
  } = stepWithAction;
  const title = `${memoryName}: ${value}`;
  const iconUrl = memoryIconUrl || memoryIconUrl !== ''
    ? memoryIconUrl
    : noImage;

  const textValue = convertValue(value);

  return (
    <div
      className={css.memoryCardContainer}
      title={title}
    >
      {isToggleShowIn && (
        <>
          <div className={css.memoryCardToggle} />
          <div className={css.memoryCardToggleEye}>
            👁
          </div>
        </>
      )}
      {type === 'card'
        ? (
          <>
            <img src={iconUrl} alt="icon" />
            <div className={css.memoryCardRegular}>
              {textValue}
            </div>
          </>
        )
        : (
          <div className={css.memoryCardMore}>
            {`+${textValue}`}
          </div>
        )}
    </div>
  );
}
